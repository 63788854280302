@import '~theme/variables';

.container {
  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: translate(0, -50%);
  width: 3rem;
  height: 3rem;
  background-color: rgba(88.2%, 88.2%, 88.2%, 0.75);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  border: 0;
}

.carouselContainer {
  @extend .container;
  left: 1.25rem;
}

.categoryContainer {
  @extend .container;
  left: -1.875rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: $on-primary;
}

.smallArrow {
  @extend .container;
  width: 1.875rem;
  height: 1.875rem;
  top: 50%;
  left: -0.5rem;
}

.leaflet {
  @extend .container;
  left: -2.125rem;
}

@media (max-width: 64rem) {
  .container {
    left: -1.5rem;
  }
  .carouselContainer {
    left: 1.25rem;
  }
  .smallArrow {
    left: -0.5rem;
  }
  .categoryContainer {
    left: -1.875rem;
  }
  .leaflet {
    left: -2.125rem;
  }
}

@media (max-width: 50rem) {
  .container {
    left: -1.5rem;
  }
  .carouselContainer {
    left: 1.25rem;
  }
  .smallArrow {
    left: -0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .leaflet {
    left: -0.875rem;
  }
}

@media (max-width: 28.125rem) {
  .container {
    left: -1.175rem;
  }
  .categoryContainer {
    left: 0;
  }
  .carouselContainer {
    left: 0.625rem;
  }
  .smallArrow {
    left: 0;
  }
  .leaflet {
    left: -0.875rem;
  }
}
