@import '~theme/variables';

.listContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bannerContainer {
  margin: 0 auto 1.5rem;
}

.bannerTitle {
  color: $grayscale-1;
  font-size: 1rem;
}

.img {
  border-radius: 0.25rem;
  cursor: pointer;
}

.videoContainer iframe {
  width: 20rem;
  height: 14.25rem;
}

.imageContainer {
  img {
    border-radius: 0.5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
}

.section {
  margin: 3rem auto;
  max-width: 71.25rem;
  content-visibility: auto;
  contain-intrinsic-size: 1px 1750px;
}

.sectionTitle {
  color: $grayscale-1;
  font-size: 1.125rem;
  font-family: $font-family-bold;
  font-weight: 700;
  margin: 0 0 1rem;
}
