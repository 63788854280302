@import '~theme/variables';

.container {
  position: absolute;
  top: 50%;
  right: -1.5rem;
  transform: translate(0, -50%);
  width: 3rem;
  height: 3rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(88.2%, 88.2%, 88.2%, 0.75);
  border-radius: 50%;
  color: black;
  cursor: pointer;
  outline: 0;
  border: 0;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: $on-primary;
}

.carouselContainer {
  @extend .container;
  right: 1.25rem;
}

.smallArrow {
  @extend .container;
  width: 1.875rem;
  height: 1.875rem;
  top: 50%;
  right: -0.5rem;
}

.categoryContainer {
  @extend .container;
  top: 50%;
  right: -1.875rem;
}

.leaflet {
  @extend .container;
  right: -2.125rem;
}

@media (max-width: 64rem) {
  .container {
    right: -1.5rem;
  }
  .categoryContainer {
    right: -1.875rem;
  }
  .carouselContainer {
    right: 1.25rem;
  }
  .smallArrow {
    right: -0.5rem;
  }
  .leaflet {
    right: -2.125rem;
  }
}

@media (max-width: 50rem) {
  .container {
    right: -1.5rem;
  }
  .carouselContainer {
    right: 1.25rem;
  }
  .smallArrow {
    right: -0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .leaflet {
    right: -0.875rem;
  }
}

@media (max-width: 28.125rem) {
  .container {
    right: -1.175rem;
  }
  .leaflet {
    right: -0.875rem;
  }
  .categoryContainer {
    right: 0;
  }
  .carouselContainer {
    right: 0.625rem;
  }
  .smallArrow {
    right: 0;
  }
}
